<script>

/* COMPOSANTS */

/* MIXINS */

/* SERVICES */
import docService from "@services/docService";

export default {
  components: {}, 
  mixins: [],
  
  metaInfo() {
    return {
      title: "help",
    }
  },
  data() {
    return {
     isDownloading: false,
    }
  },
  mounted() {
  },
  methods: {
    download() {
      this.isDownloading = true;
      docService.download().then(() => {
        this.isDownloading = false;
      });
    },
  },
}
</script>

<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-3 mb-2 col-6">
        <div class="d-block d-sm-flex flex-nowrap align-items-center">
          <div class="page-title mb-2 mb-sm-0">
            <h1>Aide</h1>
          </div>
        </div>
       </div>
     </div>
          
    <div class="row mb-2">
      <div class="col-md-3 mb-2 col-6">
        <div class="d-block d-sm-flex flex-nowrap align-items-center">
          <button @click="download()" class="btn btn-inverse-primary">
            <i class="fe fe-download pr-1" v-if="!isDownloading"/>
            <i class="pi pi-spin pi-spinner mr-1" v-if="isDownloading"/>
            Télécharger la FAQ
          </button>
        </div>
       </div>
     </div>
  </div>
</template>