<script>
import store from '@store'
import useVuelidate from '@vuelidate/core';
import {email, required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from "@components/input/AkInputText";
import AkDialog from "@components/general/AkDialog";

/* SERVICES */
import pwdService from '@services/pwdService';

export default {
  components: {AkInputText, AkDialog},
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "",
    }
  },
  data() {
    return {
      submitted: false,
      messages: [],
      pwd: {
        emailPwd: "",
      },
      user: {
        username: "",
        password: "",
      }
    }
  },
  validations() {
    return {
      user: {
        username: {required},
        password: {required},
      },
      pwd: {
        emailPwd: {required, email},
      }
    }
  },
  methods: {
    tryToLogIn() {
      this.messages = [];
      this.submitted = true;
      this.v$.user.$touch();
      if (this.v$.user.$error) return;
      
      let loader = this.$loading.show({
        canCancel: false,
        backgroundColor: "#FFF",
        color: "#064CDB",
        isFullPage: true,
      });
      return store.dispatch('logIn', {
        username: this.user.username,
        password: this.user.password,
      })
          .then(() => {
            loader.hide();
            // if connected user has temporary password, redirect to pwd change page
            if (store.state.userInformation.pwdReset) {
              this.$router.push({name: 'resetPwd'})
            } else {
              // Redirect to the originally requested page, or to the home page
              this.$router.push(this.$route.query.redirectFrom || {name: 'home'})
            }
          })
          .catch((error) => {
            loader.hide();
            this.messages=[];
            let status = error.response.status;
            if (status === 404)
              this.addErr(this.$t("login_not_found"));
            else if (status === 401)
              this.addErr(this.$t("bad_pwd"));
            else
              this.addErr(this.$t("unknown_error"));
          })
    },
    addErr(msg) {
      this.messages.push({closable: true, severity: "error", content: msg});
    },
    addSuccess(msg) {
      this.messages.push({closable: true, severity: "success", content: msg});
    },

    displayDialogUpdatePwd() {
      this.$refs.dialogUpdatePwd.show();
    },
    sendPwdReset() {
      this.v$.pwd.$touch();
      if (this.v$.pwd.$error) return;
      
      let loader = this.$loading.show({
        canCancel: false,
        backgroundColor: "#FFF",
        color: "#064CDB",
        isFullPage: true
      });
      pwdService.resetPwd(this.pwd.emailPwd).then(() => {
        this.resetPwdUpdate(loader)
        this.addSuccess("Le mail de réinitialisation de votre mot de passe vient d'être envoyé.");
      }).catch(() => {
        this.resetPwdUpdate(loader)
        this.addErr(this.$t("login_not_found"));
      })
    },
    resetPwdUpdate(loader) {
      loader.hide();
      this.messages = [];
      this.$refs.dialogUpdatePwd.hide();
      this.pwd = { emailPwd: "" };
    }
  },
}
</script>

<template>
  <div class="app-wrap">
    <div class="app-contant">
      <div class="container">
        <div class="row justify-content-center align-items-center h-100-vh">
          <div class="col-lg-4 col-sm-8">
            <div class="d-flex align-items-center">
              <div class="login pt-4">
                <img alt="Logo Dirif" class="logo-idx mb-4" src="@assets/logo_dirif.png"/>
                <Message v-for="msg of messages" :key="msg.content" :closable="msg.closable" :severity="msg.severity">
                  {{ msg.content }}
                </Message>
                <form v-on:submit.prevent="tryToLogIn">
                  <div class="row">
                    <AkInputText
                        :submitted=submitted
                        :disabled=false
                        v-model="user.username"
                        :label="$t('user.email')"
                        :validator="v$.user.username"
                        class-name="col-md-12"/>
                    <AkInputText
                        v-model="user.password"
                        :submitted=submitted
                        :disabled=false
                        inputType="password"
                        :label="$t('user.password')"
                        :validator="v$.user.password"
                        class-name="col-md-12"/>
                    <div class="col-12">
                      <div class="d-block d-sm-flex  align-items-center">
                        <a class="ml-auto pointer" @click="this.displayDialogUpdatePwd">{{ $t('pwd_forget') }}</a>
                      </div>
                    </div>
                    <div class="col-8 mt-3">
                      <img alt="Logo Citex" class="logo-idx mb-4" src="@assets/logo_citex.png" width="160" />
                    </div>
                    <div class="col-4 mt-3">
                      <button class="btn btn-primary float-right text-uppercase">{{ $t("sign_in") }}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-4 d-none d-sm-block">
            <img alt="" class="img-fluid" src="@assets/saleuse.jpg" style="max-height:100vh">
          </div>
        </div>
      </div>
    </div>
  </div>
  <AkDialog
      ref="dialogUpdatePwd"
      :title="$t('pwd_forget')"
      :validate-label="$t('send')"
      width="450px"
      @validate="sendPwdReset()"
  >
    <div class="confirmation-content">
      <AkInputText
          v-model="pwd.emailPwd"
          :label="$t('pwd_forget_enter_mail')"
          :validator="v$.pwd.emailPwd"
          class-name="col-md-12"
      />
    </div>
  </AkDialog>
</template>