<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkDropdown from "@components/input/AkDropdown";
import AkInputText from "@components/input/AkInputText.vue";
import AkCheckbox from "@components/input/AkCheckbox.vue";
import Toast from "primevue/toast";
import AkYesNoButton from "@components/input/AkYesNoButton.vue";
import AkInputMask from "@components/input/AkInputMask.vue";
import AkInputNumber from "@components/input/AkInputNumber.vue";
import AkDropdownMultiple from "@components/input/AkDropdownMultiple.vue";
import AkDateTime from "@components/input/AkDateTime.vue";
import AkInputTextArea from "@components/input/AkInputTextArea.vue";
import AkDialog from "@components/general/AkDialog.vue";


/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import imgMixin from "@mixins/imgMixin";
import urlMixin from "@mixins/urlMixin";
import dateFormatter from "@mixins/dateFormatter";
import roleMixin from "@mixins/roleMixin";
import precisionTypeInterventionMixin from "@mixins/precisionTypeInterventionMixin";

/* SERVICES */
import interventionService from "@services/interventionService";
import vehiculeImpliqueService from "@services/vehiculeImpliqueService";
import ddpService from "@services/ddpService";
import fileService from "@services/fileService";
import ceiService from "@services/ceiService";
import userService from "@services/userService";
import axeInterventionService from "@services/axeInterventionService";

/* CONSTANTES */
import sensConst from "@mixins/const/sensConst";
import origineAppelConst from "@mixins/const/origineAppelConst";
import typeInterventionConst from "@mixins/const/typeInterventionConst";
import moyensHumainsConst from "@mixins/const/moyensHumainsConst";
import moyensMaterielsConst from "@mixins/const/moyensMaterielsConst";
import etatChausseeConst from "@mixins/const/etatChausseeConst";
import conditionMeteoConst from "@mixins/const/conditionMeteoConst";
import voieConst from "@mixins/const/voieConst";
import genreVehiculeConst from "@mixins/const/genreVehiculeConst";
import natureDegatsConst from "@mixins/const/natureDegatsConst";
import intervenantConst from "@mixins/const/intervenantConst";
import actionConst from "@mixins/const/actionConst";
import vehiculeImpliqueConst from "@mixins/const/vehiculeImpliqueConst";
import {isNavigationFailure, NavigationFailureType} from "vue-router";
import AkButton from "@components/input/AkButton.vue";

export default {
  components: {
    AkButton,
    AkInputTextArea,
    AkDropdownMultiple, AkCheckbox,
    AkInputNumber, AkInputMask, AkDropdown, AkYesNoButton, Toast, AkFormView, AkInputText, AkDateTime, AkDialog
  },
  mixins: [
    randomRef, utilsMixin, dateFormatter, roleMixin, sensConst, origineAppelConst, typeInterventionConst, voieConst,
    moyensHumainsConst, moyensMaterielsConst, etatChausseeConst, conditionMeteoConst, precisionTypeInterventionMixin,
    genreVehiculeConst, natureDegatsConst, intervenantConst, actionConst, vehiculeImpliqueConst, imgMixin, urlMixin
  ],
  metaInfo() {
    return {
      title: "Détails d'une intervention",
    }
  },
  data() {
    return {
      interventionId: undefined,
      loading: true,
      submitted: false,
      currentIntervention: undefined,
      axeInterventionList: [],
      axeInterventionAutorouteList: [],
      vehiculesImpliques: [],
      ddp: [],
      currentVehiculeImplique: null,
      currentDdp: null,
      update: false,
      precision1: false,
      precision2: false,
      filesToShow: null,
      currentCei: null,
      author: null,
      selectedAgents: [],
      ceiAgents: [],
      downloading: false
    }
  },
  mounted() {
    this.interventionId = this.$route.params.id;

    let promises = []

    let p1 = interventionService.findInterventionById(this.interventionId)
    p1.then(data => {
      this.currentIntervention = data;
      let p6 = interventionService.canSeeIntervention(this.currentIntervention.id);
      p6.then((data) => {
        if (!data) {
          this.$router.push({name: 'interventionList'});
        }
      });

      let p7 = ceiService.findById(this.currentIntervention.ceiId);
      p7.then(data => {
        this.currentCei = data;
      });

      let pagents = ceiService.getCeiAgents(this.currentIntervention.ceiId);
      pagents.then(data => {
        this.ceiAgents = data;
      });

      let pselectedagents = interventionService.getInterventionAgentIds(this.interventionId);
      pselectedagents.then(data => {
        this.selectedAgents = data;
      });

      Promise.all([p6, p7, pagents, pselectedagents])

      this.currentIntervention.callDate = new Date(this.currentIntervention.callDate);
      this.currentIntervention.dateCreated = new Date(this.currentIntervention.dateCreated);
      if (this.currentIntervention.dateUpdated !== null)
        this.currentIntervention.dateUpdated = new Date(this.currentIntervention.dateUpdated);

      /*
      * Mise en forme des objets permettant de traiter les moyens humains et matériels
      * */
      this.currentIntervention.moyensHumainsUtilises = {};
      this.currentIntervention.moyensHumains.split(',').forEach(moyen => {
        let moyenSplit = moyen.split(':');
        this.currentIntervention.moyensHumainsUtilises[moyenSplit[0]] = moyenSplit[1];
      });

      this.currentIntervention.moyensMaterielsUtilises = {};
      this.currentIntervention.moyensMateriels.split(',').forEach(moyen => {
        let moyenSplit = moyen.split(':');
        this.currentIntervention.moyensMaterielsUtilises[moyenSplit[0]] = moyenSplit[1];
      });

      /*
      * Mise en forme des sens
      * */
      if (this.currentIntervention.sens === '') {
        this.currentIntervention.sensListTmp = [];
      } else {
        this.currentIntervention.sensListTmp = this.currentIntervention.sens.split(",");
      }

      /*
      * Mise en forme des intervenants
      * */
      this.currentIntervention.intervenantsList = this.currentIntervention.intervenants.split(',');

      /*
      * Mise en place des actions réalisées
      * */
      this.currentIntervention.actionsRealiseesList = this.currentIntervention.actionsRealisees.split(',');

      /*
      * Mise en forme des véhicules impliqués
      * */
      this.currentIntervention.vehiculesImpliquesDict = {};
      this.currentIntervention.vehiculesImpliques.split(',').forEach(vehicule => {
        let vehiculeSplit = vehicule.split(':');
        this.currentIntervention.vehiculesImpliquesDict[vehiculeSplit[0]] = vehiculeSplit[1];
      });

      let p2 = axeInterventionService.findAll();
      p2.then(data => {
        this.axeInterventionList = data;
        this.axeInterventionAutorouteList = data.filter(axe => axe.autoroute);
      });
      promises.push(p2)

      let p8 = userService.findById(this.currentIntervention.userId);
      p8.then(data => {
        this.author = data;
        this.author.fullName = this.author.firstName + " " + this.author.lastName;
      });
      promises.push(p8)
    });
    promises.push(p1)

    let p3 = vehiculeImpliqueService.findVehiculesImpliquesByInterventionId(this.interventionId);
    p3.then(data => {
      this.vehiculesImpliques = data;
    });
    promises.push(p3)

    let p4 = ddpService.findDdpByInterventionId(this.interventionId);
    p4.then(data => {
      this.ddp = data;
      for (let d of this.ddp) {
        d.canUpdateUnite = this.update && d.natureDegats === "AUTRES";
      }
    });
    promises.push(p4)

    let p5 = fileService.findAllByInterventionId(this.interventionId);
    p5.then(data => {
      this.filesToShow = data;
    });
    promises.push(p5)

    Promise.all(promises).then(() => {
      this.loading = false;
      setTimeout(() => {
        let msg = this.$route.query.msg;
        if (msg && msg !== "") {
          this.getRef().success(msg);
        }
      }, 100)
    });
  },
  methods: {
    getUrl(file) {
      return this.generateUrl('/file/content/' + file.id);
    },
    displayer(modelValue) {
      if (modelValue === undefined || modelValue === null || modelValue.toString() === '') return "";
      return modelValue;
    },
    download() {
      this.downloading = true;
      let p = interventionService.downloadInterventionPdf(this.currentIntervention.id);
      let promises = [p];
      p.catch(e => {
        this.getRef().hideLoader();
        this.getRef().error(this.$t("error_download_synthese"));
      });
      Promise.all(promises).finally(() => {
        this.downloading = false;
      });
    },
    inputDisplayForMoyensMaterielsConst(moyen) {
      return null != moyen ? this.$t("intervention.nb_item_prefix_label") + " " + this.displayForMoyensMaterielsConst(moyen) : ""
    },
    deleteIntervention() {
      interventionService.deleteIntervention(this.currentIntervention.id).then(() => {
        this.getRef().success(this.$t("intervention.deleted"));
        this.$router.push({name: "interventionList"})
      }).catch(() => {
        this.getRef().success(this.$t("intervention.deleted_failed"));
      })
    },
    openDeleteDialog() {
      this.$refs.dialogDelete.show();
    },
    isAxeInterventionBretelle(axeInterventionId) {
      if (this.isEmpty(axeInterventionId) || this.isEmpty(this.axeInterventionList)) return false;
      let axe = this.axeInterventionList.find(axe => axe.id === axeInterventionId);
      if (this.isEmpty(axe)) return false;
      return axe.technicalName === "BRETELLE";
    },
    isEmpty(value) {
      return value === undefined || value === null || value === '';
    },
    canDelete() {
      return this.canDeleteIntervention() && this.isRelatedToUserAffectations(this.currentIntervention);
    },
    canUpdate() {
      return this.canUpdateIntervention(this.currentIntervention) &&  this.interventionId !== undefined;
    },
  },
  computed: {
    canDownload() {
      return this.canDownloadIntervention();
    },
  },
  watch: {}
}
</script>

<template>
  <template v-if="loading">
    <ProgressSpinner/>
  </template>
  <template v-if="!loading">
    <Toast/>
    <AkFormView :ref="ref" :title="$t('intervention.details')">
      <template v-slot:action>
        <RouterLink
            v-if="canUpdate()"
            :to="{ name: 'interventionUpdate', params: { id: this.interventionId }}"
            class="btn btn-inverse-primary btn-xs">
          <i class="fe fe-edit pr-1"/>{{ $t('update') }}
        </RouterLink>
        <AkButton :loading="downloading"
                  v-if="canDownload"
                  @click="download()"
                  class-name="btn btn-inverse-primary btn-xs ml-1">
          <i class="fe fe-download pr-1"/>
          {{ $t('download_pdf') }}
        </AkButton>
        <button v-if="canDelete() && this.currentUser.userId === this.currentIntervention.userId"
                class="btn btn-xs btn-inverse-danger ml-1"
                @click="openDeleteDialog()">
          <i class="fe fe-trash pr-1"/> Supprimer
        </button>
      </template>
      <template v-slot:form>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.no_intervention_cei') }}</h5>
          <AkInputText v-model="this.currentIntervention.noInterventionCei" :disabled="true"
                       class-name="col-md-3 col-xs-4 col-xl-2"/>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.coordonnees') }}</h5>
          <template v-if="!loading && author !== undefined && author !== null">
            <AkInputText label="Auteur"
                         v-model="author.fullName"
                         :disabled="true"
                         class-name="col-md-6"/>
            <AkInputText label="Email"
                         v-model="author.email"
                         :disabled="true"
                         class-name="col-md-3"/>
            <AkInputText label="Téléphone"
                         v-model="author.tel"
                         :disabled="true"
                         class-name="col-md-3"/>
            <AkDateTime label="Date de création"
                        v-model="currentIntervention.dateCreated"
                        :disabled="true"
                        class-name="col-md-3"/>
            <AkDateTime v-if="currentIntervention.dateUpdated !== null" label="Date de modification"
                        v-model="currentIntervention.dateUpdated"
                        :disabled="true"
                        class-name="col-md-3"/>
          </template>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.call_origin') }}</h5>
          <AkDateTime v-model="currentIntervention.callDate"
                      :showTime="false"
                      :disabled="!this.update"
                      :max-date="new Date()"
                      :label="$t('intervention.call_date')"
                      class-name="col-xs-6 col-md-2"/>

          <AkInputMask v-model="currentIntervention.callTime" :disabled="!this.update"
                       :label="$t('intervention.call_hour')" class-name="col-xs-6  col-md-2" mask="99:99"
                       placeholder="__:__"/>

          <AkDropdown :label="$t('intervention.call_origin')"
                      v-model="currentIntervention.callOrigin"
                      :options=origineAppelConst
                      option-value="value"
                      option-label="label"
                      :disabled="!this.update"
                      class-name="col-md-4"/>
          <AkInputText v-if="'AUTRE' ===currentIntervention.callOrigin && null !=currentIntervention.otherCallOrigin"
                       :label="$t('intervention.other_call_origin')"
                       v-model="currentIntervention.otherCallOrigin"
                       :disabled="!this.update"
                       class-name="col-md-4"/>

          <AkInputTextArea v-model="currentIntervention.observation"
                           :disabled="!this.update"
                           rows="5"
                           :label="$t('intervention.observation')"
                           class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.lieu_intervention') }}</h5>
          <AkInputText v-if="this.currentCei != null" v-model="this.currentCei.label" :disabled="!this.update"
                       :label="$t('intervention.cei')" class-name="col-md-4"/>

          <AkDropdownMultiple
              :label="$t('intervention.agents')"
              :filter="true"
              v-model="this.selectedAgents"
              :options=this.ceiAgents
              option-value="id"
              option-label="fullName"
              :disabled="!this.update"
              :class-name="'col-md-4'"/>

          <AkInputText
              v-model="this.currentIntervention.autreAgent"
              :disabled="!this.update"
              :label="$t('intervention.autre_agent')"
              placeholder="ex: Jean Dupont, Paul Martin"
              class-name="col-md-4"/>

          <AkDropdown :label="$t('intervention.axe')"
                      v-model="currentIntervention.axeInterventionId"
                      :options=axeInterventionList
                      option-value="id"
                      option-label="libelle"
                      :disabled="!this.update"
                      class-name="col-md-4"/>

          <template v-if="this.isAxeInterventionBretelle(currentIntervention.axeInterventionId)">
            <AkInputText :label="$t('intervention.noBretelle')"
                         v-model="currentIntervention.noBretelle"
                         :disabled="!this.update"
                         class-name="col-md-4"/>
            <AkDropdown :label="$t('intervention.noAutoroute')"
                        :options="axeInterventionAutorouteList"
                        option-value="id"
                        option-label="libelle"
                        v-model="currentIntervention.autorouteId"
                        :disabled="!this.update"
                        class-name="col-md-4"/>
          </template>

          <AkDropdownMultiple
              :label="$t('intervention.sens')"
              v-model="this.currentIntervention.sensListTmp"
              :options=sensConst
              option-value="value"
              option-label="label"
              :disabled="!this.update"
              :class-name="'col-md-4'"/>

          <AkInputText v-model="this.currentIntervention.voie" :disabled="!this.update"
                       :label="$t('intervention.voie')" class-name="col-md-4"/>

          <AkInputText v-model="currentIntervention.localisation" :disabled="!this.update"
                       :label="$t('intervention.pr')" class-name="col-md-4"/>

          <AkInputText v-model="currentIntervention.commune" :disabled="!this.update"
                       :label="$t('intervention.commune')" class-name="col-md-4"/>

          <AkInputMask v-model="currentIntervention.heureArriveeSite" :disabled="!this.update"
                       :label="$t('intervention.horaire_arrivee_site')" class-name="col-md-4" mask="99:99"
                       placeholder="__:__"/>

          <AkInputMask v-model="currentIntervention.heureDepartSite" :disabled="!this.update"
                       :label="$t('intervention.horaire_depart_site')" class-name="col-md-4" mask="99:99"
                       placeholder="__:__"/>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.intervention') }}</h5>
          <AkInputMask v-model="currentIntervention.heureDebut" :disabled="!this.update"
                       :label="$t('intervention.heure_debut')" class-name="col-md-4" mask="99:99" placeholder="__:__"/>

          <AkInputMask v-model="currentIntervention.heureFin" :disabled="!this.update"
                       :label="$t('intervention.heure_fin')" class-name="col-md-4" mask="99:99" placeholder="__:__"/>

          <AkInputTextArea :label="$t('intervention.circonstances_connues')"
                           v-model="this.currentIntervention.circonstances" :disabled="!this.update"
                           class-name="col-md-4"/>

          <h5 class="mt-2 col-12">{{ $t('intervention.vehicules_impliques') }}</h5>
          <AkInputNumber v-for="typeVehicule in vehiculeImpliqueConst" :key="typeVehicule.value"
                         v-model="this.currentIntervention.vehiculesImpliquesDict[typeVehicule.value]"
                         :disabled="!this.update"
                         :label="displayForVehiculeImpliqueConst(typeVehicule.value)" class-name="col-md-2"/>

          <DataTable :paginator=false
                     :loading="loading"
                     :value="this.vehiculesImpliques"
                     class="table"
                     :sortOrder=-1
                     removableSort
                     responsiveLayout="scroll"
                     stripedRows
                     :title="$t('vehicules_impliques.title')">
            <template #empty>
              {{ $t("list_empty") }}
            </template>
            <Column field="genre" :header="$t('vehicules_impliques.genre')" :sortable="true">
              <template #body="slotProps">
                <AkDropdown label=""
                            v-model="slotProps.data.genre"
                            :options=genreVehiculeConst
                            option-value="value"
                            option-label="label"
                            :disabled="!this.update"
                            class-name="col-md-12"/>
                <AkInputText v-model="slotProps.data.typeVehicule" v-if="slotProps.data.genre === 'AUTRE_VEHICULE'"
                             placeholder="Préciser autre véhicule"
                             :disabled="!this.update"
                             class-name="col-md-12"/>
              </template>
            </Column>
            <Column field="marque" :header="$t('vehicules_impliques.marque')" :sortable="true">
              <template #body="slotProps">
                <AkInputText label=""
                             v-model="slotProps.data.marque"
                             :disabled="!this.update"
                             class-name="col-md-12"/>
              </template>
            </Column>
            <Column field="immatriculation" :header="$t('vehicules_impliques.immatriculation')" :sortable="true">
              <template #body="slotProps">
                <AkInputText label=""
                             v-model="slotProps.data.immatriculation"
                             :disabled="!this.update"
                             class-name="col-md-12"/>
              </template>
            </Column>
            <Column field="degats_materiels" :header="$t('vehicules_impliques.degats_materiels')" :sortable="true">
              <template #body="slotProps">
                <AkInputText label=""
                             v-model="slotProps.data.degatsMateriels"
                             :disabled="!this.update"
                             class-name="col-md-12"/>
              </template>
            </Column>
            <Column field="dommages_corporels" :header="$t('vehicules_impliques.dommages_corporels')" :sortable="true">
              <template #body="slotProps">
                <AkInputText label=""
                             v-model="slotProps.data.dommagesCorporels"
                             :disabled="!this.update"
                             class-name="col-md-12"/>
              </template>
            </Column>
          </DataTable>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.moyens_utilises') }}</h5>
          <AkInputNumber v-for="moyen in moyensHumainsConst" :key="moyen.value"
                         v-model="this.currentIntervention.moyensHumainsUtilises[moyen.value]" :disabled="!this.update"
                         :label="displayForMoyensHumainsConst(moyen.value)" class-name="col-md-2"/>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.moyens_materiels') }}</h5>
          <AkInputNumber v-for="moyen in moyensMaterielsConst" :key="moyen.value"
                         v-model="this.currentIntervention.moyensMaterielsUtilises[moyen.value]"
                         :disabled="!this.update"
                         :label="inputDisplayForMoyensMaterielsConst(moyen.value)" class-name="col-md-2"/>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.environnement') }}</h5>
          <AkDropdown :label="$t('intervention.etat_chaussee')"
                      v-model="currentIntervention.etatChaussee"
                      :options=etatChausseeConst
                      option-value="value"
                      option-label="label"
                      :disabled="!this.update"
                      class-name="col-md-4"/>

          <AkDropdown :label="$t('intervention.conditions_meteo')"
                      v-model="currentIntervention.conditionMeteo"
                      :options=conditionMeteoConst
                      option-value="value"
                      option-label="label"
                      :disabled="!this.update"
                      class-name="col-md-4"/>
        </div>


        <div class="form-row">
          <AkDropdown :label="$t('intervention.type_intervention')"
                      v-model="currentIntervention.typeIntervention"
                      :options=typeInterventionConst
                      option-value="value"
                      option-label="label"
                      :disabled="!this.update"
                      class-name="col-md-4"/>
          <template
              v-if="'AUTRE' === currentIntervention.typeIntervention && null !=currentIntervention.otherTypeIntervention">
            <AkInputText
                :label="$t('intervention.other_type_intervention')"
                :disabled="!this.update"
                v-model="currentIntervention.otherTypeIntervention"
                class-name="col-md-4"
            />
          </template>
          <template
              v-if="null != this.currentIntervention.precisionNiveau1 && this.currentIntervention.precisionNiveau1 !== ''">

            <AkDropdown :label="$t('intervention.precision_niveau_1')"
                        v-model="currentIntervention.precisionNiveau1"
                        :options=this.getPrecision1Const(currentIntervention.typeIntervention)
                        option-value="value"
                        option-label="label"
                        :disabled="!this.update"
                        class-name="col-md-4"/>
          </template>
          <template
              v-if="this.currentIntervention.precisionNiveau2 !== ''">
            <AkDropdown :label="$t('intervention.precision_niveau_2')"
                        v-model="currentIntervention.precisionNiveau2"
                        :options=this.getPrecision2Const(currentIntervention.precisionNiveau1)
                        option-value="value"
                        option-label="label"
                        :disabled="!this.update"
                        class-name="col-md-4"/>
          </template>


        </div>


        <div class="form-row">
          <AkDropdownMultiple :label="$t('intervenant.title')"
                              :disabled="!this.update"
                              v-model="this.currentIntervention.intervenantsList"
                              :options=intervenantConst
                              option-value="value"
                              option-label="label"
                              :displayer="this.displayer"
                              class-name="col-md-4"/>

          <AkDropdownMultiple :label="$t('action.title')"
                              :disabled="!this.update"
                              v-model="this.currentIntervention.actionsRealiseesList"
                              :options=actionConst
                              option-value="value"
                              option-label="label"
                              :displayer="this.displayer"
                              class-name="col-md-4"/>


          <template v-if="currentIntervention.presenceTiersResponsable">
            <Column field="responsable_ddp" :header="$t('vehicules_impliques.responsable_ddp')" :sortable="true">
              <template #body="slotProps">
                <AkCheckbox v-model="slotProps.data.responsableDdp"
                            :disabled="!this.update"
                            label="Responsable"
                            class-name="col-md-12"/>
              </template>
            </Column>
          </template>

        </div>
        <h5 class="mt-2">{{ $t('intervention.files') }}</h5>
        <template v-if="filesToShow.length > 0">
          <div class="form-row">
            <template v-for="file in filesToShow" :key="file.id">
              <div v-if="file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                <a :href=getUrl(file) style="margin: 5px 0 5px 5px;" target="_blank">
                  <img :alt=file.name :src=getUrl(file) style="min-height: 100%; max-width: 100%;"/>
                </a>
              </div>
            </template>
          </div>
          <div class="form-row">
            <template v-for="file in filesToShow" :key="file.id">
              <div v-if="!file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                <a :href=getUrl(file) class="card file-card">
                  <img :alt="getThumbnailAlt(file.mimetype)" :src="getThumbnail(file.mimetype)"
                       style="max-width: 30px;"/>
                  <p style="margin-left: 5px; margin-right: 5px;">{{ file.name }}</p>
                </a>
              </div>
            </template>
          </div>
        </template>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('ddp.title') }}</h5>
          <AkYesNoButton v-model="currentIntervention.ddp" :disabled="!this.update"
                         :label="$t('ddp.title')" class-name="col-md-4"/>
          <template v-if="currentIntervention.ddp">
            <AkYesNoButton v-model="currentIntervention.presenceTiersResponsable" :disabled="!this.update"
                           :label="$t('ddp.presence_tiers_responsable')" class-name="col-md-4"/>

            <DataTable :paginator=false
                       :loading="loading"
                       :value="this.ddp"
                       class="table"
                       :sortOrder=-1
                       removableSort
                       responsiveLayout="scroll"
                       stripedRows>
              <template #header>
                {{ $t('ddp.title') }}
              </template>
              <template #empty>
                {{ $t("list_empty") }}
              </template>
              <Column field="nature_degats" :header="$t('ddp.nature_degats')" :sortable="true">
                <template #body="slotProps">
                  <div class="row">
                    <AkDropdown label=""
                                v-model="slotProps.data.natureDegats"
                                :options=natureDegatsConst
                                option-value="value"
                                option-label="label"
                                :disabled="!this.update" class-name="col-md-6 col-xl-6 col-xs-12"/>
                    <AkInputText
                        v-if="'AUTRES' === slotProps.data.natureDegats && null != slotProps.data.otherNatureDegats"
                        label=""
                        v-model="slotProps.data.otherNatureDegats"
                        :disabled="!this.update"
                        class-name="col-md-6 col-xl-6 col-xs-12"
                    />
                  </div>
                </template>
              </Column>
              <Column field="unite" :header="$t('ddp.unite')" :sortable="true">
                <template #body="slotProps">
                  <AkInputText
                      :disabled=!slotProps.data.canUpdateUnite
                      placeholder=""
                      v-model="slotProps.data.unite"
                      label=""/>
                </template>
              </Column>
              <Column field="quantite" :header="$t('ddp.quantite')" :sortable="true">
                <template #body="slotProps">
                  <AkInputNumber
                      :disabled=!this.update
                      :min=0
                      placeholder=""
                      v-model="slotProps.data.quantite"
                      label=""/>
                </template>
              </Column>
            </DataTable>
          </template>
          <AkInputTextArea v-model="currentIntervention.ddpObservations" :label="$t('ddp.observations')"
                           class-name="col-lg-12" disabled/>
        </div>
      </template>
    </AkFormView>
  </template>
  <AkDialog ref="dialogDelete"
            :auto-hide-on-validate="true"
            :cancel-label="$t('no')"
            :title="$t('intervention.delete_dialog')"
            :validate-label="$t('yes')"
            width="450px"
            @validate="deleteIntervention">
    <div class="confirmation-content">
      <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
      <span>{{ $t('intervention.confirm_delete') }}</span>
    </div>
  </AkDialog>
</template>
<style>
.trash-can {
  cursor: pointer;
  border-radius: 0 .25rem .25rem 0;
  margin: 5px 5px 5px 0;
  border: 1px solid #e7eaf3;
  border-left-width: 0;
}

.trash-can:hover {
  color: #40b4e5;
  background-color: #40b4e520;
}

.file-card {
  align-items: center;
  flex-direction: row;
  padding: 5px 5px;
  margin: 5px 0;
  border-radius: .25rem;
  width: -webkit-fill-available;
}
</style>